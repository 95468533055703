<template lang="pug">
div.container
  b-row(v-if="processingRequest")
    loading-spinner(message="Updating profile...")
  b-row(v-else-if="showEmailChangedMessage")
    b-alert.text-center(show variant="success") <strong>Success!</strong> Your membership profile has been updated. Because your email was changed we sent you a new edit link for security reasons. Please use it to continue editing your profile.
  b-row(v-else-if="!member.membershipType")
    loading-spinner(message="Loading memberhip data...")
  div(v-else)
    b-row.text-center(align-h="center").mb-8
      h1 Update Contact Information

    b-row(align-h="center")
      div.col-md-10
        b-form
          b-row(align-h="center")
            b-form-group.col-sm-4.p-2(label="First Name" label-for="firstName")
              b-input(type="text" name="firstName" v-model="editedMember.firstName" required)
            b-form-group.col-sm-4.p-2(label="Last Name" label-for="lastName")
              b-input(type="text" name="lastName" v-model="editedMember.lastName" required)
          b-row(align-h="center")
            b-form-group.col-sm-4.p-2(label="Email" label-for="email")
              b-input(type="email" name="email" v-model="editedMember.email" @change="checkEmail" required)
            b-form-group.col-sm-4.p-2(label="City" label-for="city")
              b-input(type="text" name="city" v-model="editedMember.postOffice")

          b-row(align-h="center").m-4
            b-alert.text-center(show variant="success" v-if="emailChecked && !emailFree && editedMember.email != member.email") {{editedMember.email}} is already taken. Pick something else?

          b-row(align-h="center").m-4
            p(v-if="!isFormValid") Fill in all the fields.
            p(v-else-if="!emailChecked && isFormValid") Checking email...
            div.text-center(v-if="emailChecked && emailFree && isFormValid")
              b-alert(show variant="info" v-if="member.email != editedMember.email") <strong>You are about to change your email address!</strong> Please make sure it's the right one so you don't get locked out of your account.
                p.mt-1 A new profile edit link will be sent to the new email address. Please click it to continue editing and/or renewing your membership.
                p Sorry for the inconvenience but security is important!
              b-button(variant="success" size="lg" @click="updateMember(editedMember)") Save Profile
</template>

<script>
export default {
  data: function () {
    return {
      member: {
        membershipType: null,
        firstName: '',
        lastName: '',
        email: '',
        postOffice: ''
      },
      editedMember: {
        membershipType: null,
        firstName: '',
        lastName: '',
        email: '',
        postOffice: ''
      },
      processingRequest: false,
      emailFree: true,
      emailChecked: true,
      showEmailChangedMessage: false
    }
  },
  computed: {
    isFormValid: function () {
      let result = true
      if (this.editedMember.firstName === '' ||
        this.editedMember.lastName === '' ||
        this.editedMember.email === '' ||
        this.editedMember.postOffice === '') { result = false }
      return result
    }
  },
  methods: {
    checkEmail: function () {
      this.emailFree = false
      this.emailChecked = false

      if (this.editedMember.email !== this.member.email) {
        this.axios.post('/api/cards/emailcheck', this.editedMember)
          .then(res => {
            if (res.data === 'free') { this.emailFree = true } else { this.emailFree = false }
            this.emailChecked = true
          })
          .catch(err => {
            this.$notify({
              group: 'error',
              type: 'error',
              title: 'Checking email failed. Please let us know!',
              text: err.response.data,
              duration: -1
            })
          })
      } else {
        this.emailChecked = true
        this.emailFree = true
      }
    },
    updateMember: function () {
      this.processingRequest = true
      this.axios.put('/api/individual-members/editWithToken/' + this.member._id + '/' + this.member.token, this.editedMember)
        .then(res => {
          this.$notify({
            group: 'success',
            type: 'success',
            title: 'Profile updated'
          })

          if (res.data.email !== this.member.email) { this.showEmailChangedMessage = true } else { this.$router.push({ path: '/individual-members/' + this.$route.params.id + '/' + this.$route.params.token }) }
          this.processingRequest = false
        })
        .catch(err => {
          this.errorMessage = err
          this.processingRequest = false
          this.showEditedMember = false
          this.$notify({
            group: 'error',
            type: 'error',
            title: 'Updating member details failed',
            duration: -1
          })
        })
    }
  },
  mounted: function () {
    this.axios.get('/api/individual-members/editWithToken/' + this.$route.params.id + '/' + this.$route.params.token)
      .then(res => {
        this.member = res.data
        this.editedMember = JSON.parse(JSON.stringify(this.member))
        this.emailChecked = true
      })
      .catch(err => {
        this.errorMessage = err
      })
  }
}
</script>
